<template>
    <div class="policybox">
        <div class="ssf" >
                <Row type="flex" justify="space-around" class="code-row-bg">
                    <Col span="6" push="7" class-name="zixing" @click.native="shouye()">首页</Col>
                    <Col span="6" push="2"   class-name="zixing">>政策法规</Col>
                    <Col span="6"  class-name="zi">>搜索结果</Col>
              </Row>
            
          </div>
          <div class="jigoubox" >
               <div class="falv">
                   <div class="falvvv">
                        <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                    <div class="ziti">法规文件</div>
                   </div>
                  <div>
                       <Input v-model="value" placeholder="请输入关键字" class="input" />
                        <Button type="primary" icon="ios-search" @click="search()">搜索</Button>
                         <Button type="info"  class="btn" @click="fanju()">返回</Button>
                  </div>
               </div>
                <div class="hengxian"></div>
                <div class="contentr" v-if="!noSearch">
                    <div class="" v-for="(item,index) in contentr" :key="index">
                        <div class="tit" v-html="brightenKeyword(item.newsTitle,value)"  @click="leix(item.newsTitle,item.createTime,item.newsResource,item.content,item.accessoryUrl,item.newsMode,item.accessoryName,item.id)">{{item.newsTitle}}</div>
                        <div class="con" v-html="brightenKeyword(item.content,value)" :style="{display:item.newsMode==2?'none':'block'}"></div>
                        <div class="xiaozi">
                            <div>来源：{{item.newsResource}}</div>
                            <div>日期：{{item.createTime}}</div>
                        </div>
                            <div class="hengxiana"></div>
                    </div>
                      <!-- 分页 -->
                  <el-pagination class="fenye"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page= currentPage
                        :page-sizes="[15]"
                        :page-size= pageSize
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                   </el-pagination>
                </div>
                 <div ref="wupipei" v-else>
                     <h1 class="baoqian">很抱歉！无搜索结果</h1>
                </div>
            
          </div>
    </div>
</template>
<script>
import {incrReadingNum, searchMessage } from "../../../woke/xinx.js"
import { formatDate } from '../../../commm/time'
export default {
    data(){
        return{
            value:"",
            contentr:[],
            valuel:'',
            noSearch:false,
            currentPage:1, 
            pageSize: 15,
            totalCount:null,
            mess:"",
            cur:1,
            page:10,
        }
    },
    created(){
         if (this.$route.query.value) {
            this.value = this.$route.query.value;
            this.currentPage = this.$route.query.currentPage;
            this.pageSize = this.$route.query.pageSize;
            // console.log(this.value, this.currentPage, this.pageSize,);
            this.search(this.value, this.currentPage, this.pageSize);
        }
    },
    activated(){
            this.$bus.$on("Search", (data) => {
            // console.log(data, "sghdsj");
            this.mess = data.value
            this.cur = data.currentPage;
            this.page = data.pageSize;
            this.search(this.value,this.cur,this.page)
        });
        
    },
    methods:{
          //改变分页的每页的页数
     handleSizeChange(size) {
        //  console.log(size);
        this.pageSize = size
        this.search(this.value, this.pageSize,this.currentPage)
    //    console.log(this.pageSize) //每页下拉显示数据
     },
     // 改变分页的当前页面
     handleCurrentChange(currentPage) {
        //  console.log(currentPage);
       this.currentPage = currentPage
        this.search(this.value, this.pageSize,this.currentPage)
    //    console.log(this.currentPage) //点击第几页
     },
        shouye(){
            this.$router.push("/")
        },
        fanju(){
           this.$router.go(-1);
           
        },
        search(value,currentPage,pageSize){
                searchMessage({
                    message:this.value,
                    maxPage:this.pageSize,
                    curPage:this.currentPage,
                    type:4,
                }).then(res => {
                    // console.log(res,"法规搜索");
                    this.contentr= res.data.data.list;
                    this.totalCount = res.data.data.total
                    if(this.contentr.length==0) { // 没有数据
                        this.noSearch = true
                    } else {// 有数据
                        this.noSearch = false
                    }
                })
           
        },
    
        
        leix(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName,id){
        //    console.log(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName);
            console.log(id);
                incrReadingNum({
                    id:id,
                    type:1
                }).then(res=>{
                    console.log(res);
                })
          if(newsMode==1){
            //   console.log(11);
               this.$router.push({
               name:"falv",
               params:{
                      tit:newsTitle,
                      time:createTime,
                      newsResource:newsResource,
                      content:content,
                      accessoryUrl:accessoryUrl,
                      newsMode:newsMode,
                      accessoryName:accessoryName
                }
            });
          }else if(newsMode==2){
               window.open(content,"_blank")
          }
      },
        brightenKeyword(val, keyword) {
            val = val + '';
            if (val.indexOf(keyword) !== -1 && keyword !== '') {
                    return val.replace(keyword, '<font color="red">' + keyword + '</font>')
            } else {
                return val
            }
        }
        
    },
    mounted(){
         
    },
     computed: {
    
    },
      filters:{
       /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/ 
    formatDate(time) {
        var date = new Date(time);
        return formatDate(date, 'yyyy-MM-dd'); 
    },
    }
}
</script>
<style scoped>
.policybox{
    min-height:100vh;
 
    margin-top:60px; 
 
    margin-bottom:30px; 
}
.code-row-bg{
   width: 350px;
    margin-left: 298px;
    margin-bottom: 20px;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}
.jigoubox{
    width: 100px;
    margin-left: 360px;
    margin-top: 30px;
    /* display: flex;
    justify-content: space-around */
}
.hengxian{
     margin-top: 20px;
     width:1000px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
    margin-left: 60px;
    margin-bottom: 10px;
}
.hengxiana{
      margin-top: 20px;
     width:1000px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
   margin-bottom: 10px;
}
.ziti{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2C3B5B;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}
.falv{
    width: 1000px;
    display: flex;
    justify-content: space-between;
    margin-left: 60px;
}
.input{
    width: 200px;
}
.btn{
    width: 90px;
    height: 34px;
    background: #2D8CF0;
    color: white;
    margin-left: 5px;
}
.contentr{
    width: 1100px;
    background:  #FFFFFF;
    margin-left: 60px;
   
}
.tit{
    font-size: 18px;
font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
font-weight: bold;
color: #1A1A1A;
line-height: 24px;
cursor: pointer;
}
.con{
    width: 1100px;
    height: 40px;
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    /* line-height: 21px; */
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.xiaozi{
    width: 500px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #808080;
    line-height: 16px;
    margin-top: 20px;
}
.fgenye{
    
    width: 600px;
    margin-top: 500px;
    margin-left: 600px;
}
 .baoqian{
     width: 500px;
    margin-top: 200px;
     margin-left: 520px;
}
.tibiao{
    width: 10px;
    height: 16px;
    margin-top: -3px;
}
.falvvv{
    margin-top: 5px;
}
.fenye{
     width: 600px;
     margin-top: 50px;
     margin-left: 605px;
 }
</style>